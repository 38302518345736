.container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #4a4a4a;
}

p {
    margin-bottom: 16px;
    color: #555;
}

.text-center {
    text-align: center;
}

.text-muted {
    color: #777;
}

ul {
    margin: 10px 0 20px 20px;
    padding-left: 20px;
}

ul li {
    margin-bottom: 10px;
}

a {
    color: #1a73e8;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

strong {
    color: #333;
}

@media (max-width: 768px) {
    .container {
        margin: 10px;
        padding: 15px;
    }
}