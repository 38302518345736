.pagination {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    list-style-type: none;
  }
  
  .pagination li {
    margin: 0 5px;
  }
  
  .pagination li a {
    color: #007bff;
    cursor: pointer;
  }
  
  .pagination .active a {
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    border-radius: 5px;
    padding: 5px 10px;
  }
  